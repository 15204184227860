import * as React from 'react';

import MainLayout from '../components/Layout/MainLayout';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import MySEO from '../shared/MySEO';
import styled from 'styled-components';
import { colorsV2 } from '../style/colors-v2';
import { Typography } from '../components/DesignSystemV2/Typography';
import { withMarketingSection } from '../components/withMarketingSection';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { deviceBreakpoints } from '../utils/hooks/useDeviceQuery.hook';
import Button from '@dolstaff/shared/es/Button';
import { uniqBy } from 'lodash';

/** Explore DOL content **/
const ExploreDolContentContainer = styled.div`
  .content {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    .button {
      background-color: ${colorsV2.white100};
      padding: 8px 16px;

      :hover {
        background-color: ${colorsV2.white100};
      }
    }
    a {
      margin-top: 8px;
    }
    a:not(:last-child) {
      margin-right: 16px;
    }
  }
`;
const useCourseData = () => {
  const data = useStaticQuery(graphql`
    {
      allCourses: allGoogleSpreadsheetDolLandingPageMarLevelData(
        filter: { slug: { ne: null }, classListing: { ne: "unlisted" } }
      ) {
        nodes {
          slug
          levelDisplay
          classGroup
        }
      }
    }
  `);
  const coursesWithSlug = [
    { slug: '/hall-of-fame', levelDisplay: 'Điểm & Review' },
    { slug: '/schedule', levelDisplay: 'Lịch học & Khuyến mãi' },
    { slug: '/linearteachers', levelDisplay: 'Giáo viên' },
    { slug: '/hoc-ielts-online', levelDisplay: 'IELTS Online' },
    ...uniqBy(data.allCourses.nodes, 'slug')
  ];

  return coursesWithSlug;
};

const routeLink = item => {
  if (item.classGroup?.includes('TOEIC')) {
    return '/toeic';
  }
  if (item.classGroup?.includes('SAT')) {
    return '/sat';
  }
  if (item.classGroup?.includes('Tiếng Anh')) {
    return '/tieng-anh-giao-tiep';
  }
  return item.slug;
};

const ExploreDolContent = ({ staticPageLink }) => {
  const courses = useCourseData();
  return (
    <ExploreDolContentContainer>
      <Typography variant="regular/16-24" style={{ color: colorsV2.gray100 }}>
        Tham khảo khóa học của DOL cung cấp
      </Typography>
      <div className="content">
        {courses.map(item => (
          <Link key={item.slug} to={routeLink(item)}>
            <Button type="link">{item.levelDisplay}</Button>
          </Link>
        ))}
      </div>
    </ExploreDolContentContainer>
  );
};

const NotFoundPageContainer = styled.div`
  .not-found-container {
    margin: 0;
    padding-top: 4em;
    padding-bottom: 4em;
    background-color: ${colorsV2.black5};
  }
  .not-found-content {
    @media ${deviceBreakpoints.md} {
      max-width: 712px;
    }
  }
  .not-found-text {
    a {
      color: ${colorsV2.primary100};
      :hover {
        border-bottom: 1.5px solid ${colorsV2.primary100};
      }
    }
    padding-bottom: 60px;
    text-align: center;
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 16px;
  }
`;
const NotFoundContent = withMarketingSection(
  () => (
    <>
      <div className="not-found-text">
        <Typography
          desktopVariant="semi-bold/32"
          tabletVariant="semi-bold/32"
          variant="semi-bold/24"
          style={{ color: colorsV2.black100 }}
        >
          Không tìm thấy trang
        </Typography>
        <Typography variant="regular/16-24" style={{ color: colorsV2.gray100 }}>
          Trang bạn đang tìm kiếm tồn tại trên trang web của chúng tôi.
          <br />
          Bạn có thể lựa chọn tìm hiểu các nội dung dưới đây hoặc vào trang chủ{' '}
          <Link to="/">tại đây</Link>
        </Typography>
      </div>
      <ExploreDolContent />
    </>
  ),
  { className: 'not-found-container' },
  { className: 'not-found-content' }
);

const NotFoundPage = () => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister={false}
    >
      <MySEO
        noSEO
        title="Not Found - DOL IELTS Đình Lực | dolenglish.vn - IELTS Đình Lực"
        description="Luyện thi IELTS 7.0+ bằng phương pháp tư duy tuyến tính độc quyền và dàn giáo viên IELTS 8.5+ tại Trung tâm IELTS Đình Lực - DOL English HCM"
      >
        <link rel="canonical" href="https://www.dolenglish.vn/404" />
      </MySEO>

      <NotFoundPageContainer>
        <NotFoundContent />
      </NotFoundPageContainer>
    </MainLayout>
  );
};

NotFoundPage.propTypes = {};

NotFoundPage.defaultProps = {};

export default React.memo(NotFoundPage);
